import {GET_MEMBERS, GET_TERRITORY, SET_LOADING_MEMBERS_TRUE, SET_LOADING_MEMBERS_FALSE,
    SET_EMPTY_MEMBERS} from "../actions/AnalizeActions";


const initialState = {
    territories: {
        regions: [{ pk: "", repr: "" }],
        branches: [{ p_ref: "", pk: "", repr: "" }],
        managers: [{ p_ref: "", pk: "", pp_ref: "", repr: "" }]
    },
    members: [],
    loading_members: false
}

export const AnalizeReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_TERRITORY: {
            return {...state, territories: action.payload};
        }
        case GET_MEMBERS: {
            return {...state, members: action.payload};
        }
        case SET_EMPTY_MEMBERS: {
            return {...state, members: action.payload};
        }
        case SET_LOADING_MEMBERS_TRUE: {
            return {...state, loading_members: action.payload};
        }
        case SET_LOADING_MEMBERS_FALSE: {
            return {...state, loading_members: action.payload};
        }
        default: {
            return state;
        }
    }
}
