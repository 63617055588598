import React, {useEffect, useReducer} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AuthContext, initialState} from "../../AuthContext";
import {Loading} from "../../../components/ui/Loading/Loading";
import {enteredAppUser, reducer} from "./auxAuthProvider";
import {AuthService} from "../../../services/AuthService";
import {WebSocketAppService} from "../../../services/WebSocketServices/WebSocketAppService";


/**
 * component: in the AuthContext put user data of the type like this:
 * {role: 'SA', username: 'SKolodkin', name: 'Колодкин Степан', id: 41, email: '...@....ru'},
 * information about its state(isAuthenticated and isInitialised) and functions
 * login and logout from AuthProvider
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
export const AuthProvider = ({children}) => {
    const dispatchWebSocketApp = useDispatch();
    const wsApp = useSelector(state => state.WebSocketConnections.WebSocketConnection);
    const [state, dispatch] = useReducer(reducer, initialState);

    const login = async (username, password) => {
        await AuthService.login(username, password);
        let user = await enteredAppUser(dispatch, 'Login');
        WebSocketAppService.createConnection(wsApp, user, dispatchWebSocketApp);
        return user;
    };

    const logout = async () => {
        await AuthService.logout();
        dispatch({type: 'LOGOUT'});
        console.log('Logout successfully');
        WebSocketAppService.closeConnection(wsApp, dispatchWebSocketApp);
    };

    useEffect(() => {
        (async () => {

            try {
                let isUserAuthenticated = document.cookie.split('; ').find((row) => row.startsWith('authenticated'))?.split('=')[1];

                if (isUserAuthenticated === 'True' && await AuthService.verify()) {
                    let user = await enteredAppUser(dispatch, 'Verify');
                    WebSocketAppService.createConnection(wsApp, user, dispatchWebSocketApp);
                } else {
                    console.log('Verify false');
                    dispatch({ type: 'NO_TOKEN' });
                }

            } catch (err) {
                console.error(err);
                dispatch({type: 'ERROR'});
            }
        }
        )()
    }, [])  // only once after initial render, because useEffect(..., [])

    if (!state.isInitialised) {
        return (
            <>
                {/*<div>{'Error'}</div>*/}
                <Loading />
            </>
        )
    }

    return (
        <AuthContext.Provider value={
            {
                ...state,
                method: 'JWT',
                login,
                logout,
            }
        }
        >
            {children}
        </AuthContext.Provider>
    )
};
