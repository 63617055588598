import {webSocketAccessRole} from "../../auth/authRoles";
import {WebSocketService} from "./WebSocketService";
import {setCloseWebSocketConnection} from "../../redux/actions/WebSocketAction";


export class WebSocketAppService {

    static createConnection = (wsApp, user, dispatchWebSocketApp) => {
        this.closeConnection(wsApp, dispatchWebSocketApp);

        if (webSocketAccessRole.includes(user.role)) {
            WebSocketService.createWebSocketConnection(dispatchWebSocketApp, user.role, user.id);
        }
    }

    static closeConnection = (wsApp, dispatchWebSocketApp) => {
        if (wsApp) {
            WebSocketService.closeWebSocketConnection(wsApp);
            dispatchWebSocketApp(setCloseWebSocketConnection());
        }
    }
}
