const checkWebSocket = (webSocketConnection) => {
    return Boolean(webSocketConnection);
}

const checkWebSocketState = (webSocketConnection) => {
    switch (webSocketConnection.readyState) {
        case 0:
            return 'CONNECTING';
        case 1:
            return 'OPEN';
        case 2:
            return 'CLOSING';
        case 3:
            return 'CLOSED';
        default:
            return null;
    }
}

export const isOpenWebSocketConnection = (webSocketConnection) => {
    if (!checkWebSocket(webSocketConnection)) { return false; }
    return (checkWebSocketState(webSocketConnection) === 'OPEN');
}

export const isClosedWebSocketConnection = (webSocketConnection) => {
    if (!checkWebSocket(webSocketConnection)) { return false; }
    return (checkWebSocketState(webSocketConnection) === 'CLOSED');
}
