import {$api} from "../../api/indexAPI";
import {api_history_accruals_detail_by_filter_url} from "../../api/apiUrls";


export const GET_SELECTED_CASHBACK_MEMBERS                  = 'GET_SELECTED_CASHBACK_MEMBERS';
export const SET_IS_LOADING_SELECTED_CASHBACK_MEMBERS_TRUE  = 'SET_IS_LOADING_SELECTED_CASHBACK_MEMBERS_TRUE';
export const SET_IS_LOADING_SELECTED_CASHBACK_MEMBERS_FALSE = 'SET_IS_LOADING_SELECTED_CASHBACK_MEMBERS_FALSE';

export const getSelectedCashbackMember = (
    region_alias = 'null',
    start_report = 'null',
    end_report = 'null'
        ) => (dispatch) => {
            dispatch({ type: SET_IS_LOADING_SELECTED_CASHBACK_MEMBERS_TRUE, payload: true });
            $api.get(api_history_accruals_detail_by_filter_url,
                {params: {
                                'region_alias': region_alias || 'null',
                                'start_report': start_report || 'null',
                                'end_report': end_report || 'null',
                                }
                        }).then((response) => {
                            dispatch({
                                type: SET_IS_LOADING_SELECTED_CASHBACK_MEMBERS_FALSE,
                                payload: false
                            });
                            dispatch({
                                type: GET_SELECTED_CASHBACK_MEMBERS,
                                payload: response.data.result,
                            });
                        })
                    }
