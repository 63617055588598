export const userHasPermission = (user, pathname, routes) => {

    if (!user) { return false; }

    // if pathname in routes matched <- route
    // else matched <- undefined
    const matched = routes.find((route) => route.path === pathname);

    return (matched && matched.auth && matched.auth.length ? matched.auth.includes(user.role) : false);
}
