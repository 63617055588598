import {$api} from "../api/indexAPI";
import {api_login_url, api_logout_url, api_refresh_url, api_verify_url} from "../api/apiUrls";


export class AuthService {

    static headers_common_add_csrftoken_and_console_log_response(response) {
        $api.defaults.headers.common['X-CSRFToken'] = response.headers["x-csrftoken"]
        // console.log(response);  31.05.2023
    }

    static async login(username, password) {
        try {
            const response = await $api.post(api_login_url, {username, password});
            AuthService.headers_common_add_csrftoken_and_console_log_response(response);
        } catch (err) {
            console.log(err.response?.data?.message);
        }
    };

    static async verify() {

        try {
            const response = await $api.get(api_verify_url);
            AuthService.headers_common_add_csrftoken_and_console_log_response(response);
            return response.status === 200;
        } catch (err) {
            console.log(err.response?.data?.message);
        }
    }

    static async refresh(error) {

        const originalRequest = error.config;
        const flag = error.response?.data?.flag;

        if (error.response.status === 403 && flag && (flag === 'invalid_refresh_token' || flag === 'no_user')) {
            if (window.location.href !== `${process.env.REACT_APP_BASE_URL}/signin`) {
                console.log(`HTTP_403_FORBIDDEN: ${flag}`);
                window.location.href = '/signin';
                return false;
            } else {
                return false;
            }
        }

        if (error.response.status === 401 && error.config && !error.config._isRetry) {
            originalRequest._isRetry = true;
            try {
                const response = await $api.post(api_refresh_url);
                AuthService.headers_common_add_csrftoken_and_console_log_response(response);
                return $api.request(originalRequest);
            } catch (err) {
                console.log(err.response?.data?.message);
                }
        } throw error;
    };

    static async logout() {
        try {
            const response = await $api.post(api_logout_url);
            AuthService.headers_common_add_csrftoken_and_console_log_response(response);
        } catch (err) {
            console.log('LOGOUT FAILED');
            console.log(err.response?.data?.message);
        }
    };

}
