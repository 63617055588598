import {DELL_SELECTED_MEMBER, GET_SELECTED_MEMBER} from "../actions/MemberActions";


const initialState = null;

export const MemberReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_SELECTED_MEMBER: {
            return action.payload;
        }
        case DELL_SELECTED_MEMBER: {
            return action.payload;
        }
        default: {
            return state;
        }
    }
}
