import {createContext} from 'react';

export const initialState = {
    isAuthenticated: false,
    isInitialised: false,
    user: null,
}

export const AuthContext = createContext({
    ...initialState,
    method: 'JWT',
    login: () => {},
    logout: () => {},
})
