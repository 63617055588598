import {$api} from "../../api/indexAPI";
import {api_customers_analytics_url, api_territory_url} from "../../api/apiUrls";


export const GET_TERRITORY             = 'GET_TERRITORY';
export const GET_MEMBERS               = 'GET_MEMBERS';
export const SET_LOADING_MEMBERS_TRUE  = 'SET_LOADING_MEMBERS_TRUE';
export const SET_LOADING_MEMBERS_FALSE = 'SET_LOADING_MEMBERS_FALSE';
export const SET_EMPTY_MEMBERS         = 'SET_EMPTY_MEMBERS';

export const getTerritory = () => (dispatch) => {
    $api.get(api_territory_url).then((response) => {
        dispatch({
            type: GET_TERRITORY,
            payload: response.data,
        })
    })
}

export const setEmptyMembers = () => (dispatch) => {
    dispatch({
        type: SET_EMPTY_MEMBERS,
        payload: [],
    })
}

export const getMembers = (
    start_report  = 'null',
    end_report    = 'null',
    find_customer = 'null',
    region_id     = 'default',
    branch_id     = 'default',
    manager_id    = 'default',
    is_cashback   = 'default',
) => (dispatch) => {
    $api.get(api_customers_analytics_url, {
        params:
            {
                'start_report':  start_report,
                'end_report':    end_report,
                'find_customer': find_customer || 'null',
                'region_id':     region_id,
                'branch_id':     branch_id,
                'manager_id':    manager_id,
                'is_cashback':   is_cashback,
            }
    }).then((res) => {
        dispatch({
            type: GET_MEMBERS,
            payload: res.data.result,
        })
    }).then(() => {
        dispatch({
            type: SET_LOADING_MEMBERS_FALSE,
            payload: false,
        })
    })
}

export const setLoadingMembersTrue = () => (dispatch) => {
    dispatch({
        type: SET_LOADING_MEMBERS_TRUE,
        payload: true,
    })
}
