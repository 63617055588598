import {$api} from "../api/indexAPI";
import {api_get_user_data_url} from "../api/apiUrls";


export class UserService {

    static async getUser() {
        const response = await $api.get(api_get_user_data_url);
        return response.data;
    };

}
