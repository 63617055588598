import React from 'react';
import { useRoutes } from 'react-router-dom';
import { Provider } from 'react-redux';

import { Store } from './redux/Store';
import { AllPages } from './routes/routes';
import { SettingsProvider } from 'contexts/providers/SettingsProvider/SettingsProvider';
import { AuthProvider } from "./contexts/providers/AuthProvider/AuthProvider";
import { MatxTheme } from "./components/Matx/MatxTheme/MatxTheme";


export const App = () => {

    const all_pages = useRoutes(AllPages());

    return (
        <Provider store={Store}>  {/* store={Store} - database conditionally */}
            <SettingsProvider>
                <MatxTheme>
                    <AuthProvider>  {/* in the AuthContext put user data */}
                        { all_pages }
                    </AuthProvider>
                </MatxTheme>
            </SettingsProvider>
        </Provider>
    )
}

/*
    hook always returns logic without layout
    hook is used before conditions
    useRoutes - hook,
 */
