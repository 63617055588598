import {SET_COUNT_ROWS, SET_MEMBER_STATUS} from "../actions/ParamsDashboardMainTableAction";


const initialState = {
    countRows: 10,
    memberStatus: 'no-matter',
}

export const ParamsDashboardMainTableReducer = function (state = initialState, action) {
    switch (action.type) {
        case SET_COUNT_ROWS: {
            return {...state, countRows: action.payload};
        }
        case SET_MEMBER_STATUS: {
            return {...state, memberStatus: action.payload};
        }
        default: {
            return state;
        }
    }
}
