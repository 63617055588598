import React from 'react';
import {Loading} from "../ui/Loading/Loading";

export const WrapperChildrenSuspense = ({children}) => {
    return (
        <React.Suspense fallback={<Loading />}>
            {children}
        </React.Suspense>
    );
}
