import {$api} from "../../api/indexAPI";
import {api_customers_prop_main_url} from "../../api/apiUrls";


export const GET_SELECTED_MEMBER = 'GET_SELECTED_MEMBER';
export const DELL_SELECTED_MEMBER = 'DELL_SELECTED_MEMBER';

export const getSelectedMember = (phone, click = false) => (dispatch) => {
    $api.get(api_customers_prop_main_url, {params: { 'phone': phone, }}).then(
        (response) => {
            dispatch({
                type: GET_SELECTED_MEMBER,
                payload: {...response.data.result, phone: phone, click: click}
            })
        })
    }

export const dellSelectedMember = () => (dispatch) => {
    dispatch({
        type: DELL_SELECTED_MEMBER,
        payload: null
    })
}
