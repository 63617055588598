import axios from "axios";

import {waitingTimeResponsesBackend} from "../constants/constant";
import {AuthService} from "../services/AuthService";


/** $api - instance of axios. all requests to the backend
 * when creating an instance, the general settings for all types of requests are specified*/
export const $api = axios.create({
    withCredentials: true,  //  cookies are attached to each request automatically
    timeout: waitingTimeResponsesBackend,
    headers: {'Content-Type': 'application/json', 'Accept': 'application/json'},
});


$api.interceptors.response.use(
    (response) => {
        return response;  // there is no error code in the response from the server, we skip the response from the server for further processing
    },
    async (error) => {
        return AuthService.refresh(error);
    }
);
