import React from 'react';
import { styled } from '@mui/system';
import { CircularProgress } from '@mui/material';


const StyledLoading = styled('div')({
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
});


/**
 * custom react component: rotating loading ring
 * */
export const Loading = () => {

    return (
        <StyledLoading>
            <CircularProgress />
        </StyledLoading>
    )
}
