import React from 'react';
import { Loading } from "../ui/Loading/Loading";


export const WrapperComponentSuspense = (Component) => {
    return (props) => {
        return (
            <React.Suspense fallback={<Loading />}>
                <Component {...props} />
            </React.Suspense>
        )
    };
};
