import {$api} from "../../api/indexAPI";
import {api_customers_list_addresses_url, api_customers_prop_addr_url} from "../../api/apiUrls";

export const GET_ADDRESSES = 'GET_ADDRESSES';
export const GET_ADDRESSES_USER = 'GET_ADDRESSES_USER';


export const getUserAddressesList = (phone) => (dispatch) => {
    $api.get(api_customers_prop_addr_url, { params: { 'phone': phone, }}).then(
        (res) => {
            dispatch({
                type: GET_ADDRESSES_USER,
                payload: res.data.result,
            });
        });
}

export const getAddressList = (phone, inn) => (dispatch) => {
    $api.get(api_customers_list_addresses_url, { params: { 'phone': phone, 'inn': inn }}).then(
        (res) => {
            dispatch({
                type: GET_ADDRESSES,
                payload: res.data.result,
            });
        });
}
