import React from 'react';

import {MatxLayouts} from './index';
import {useSettings} from 'hooks/useSettings';
import {WrapperChildrenSuspense} from "../../hoc/WrapperChildrenSuspense";


export const MatxLayout = (props) => {
    const {settings} = useSettings();
    const Layout = MatxLayouts[settings.activeLayout];

    return (
        <WrapperChildrenSuspense>
            <Layout {...props} />
        </WrapperChildrenSuspense>
    )
}
