import {$api} from "../../api/indexAPI";
import {api_customers_list_url} from "../../api/apiUrls";

export const GET_ALL_CUSTOMERS = 'GET_ALL_CUSTOMERS';
export const PAGES_SIZE = 'PAGES_SIZE';
export const SEARCH_TEXT = 'SEARCH_TEXT';


export const getAllCustomers = (order_by, desc, page_num, search, address_id, enabled, size) => (dispatch) => {
    $api.get(api_customers_list_url, {
        params:
            {
                'order_by': order_by || 'date',  // либо передаем, либо по дате
                'desc': desc === 'desc',
                'page_num': page_num,
                'search': search,
                'address_id': address_id,
                'enabled': enabled,
                'size': size,
            }
    }).then((res) => {
        dispatch({
            type: GET_ALL_CUSTOMERS,
            payload: res.data.result,
        });
        delete res.data.result;
        dispatch({
            type: PAGES_SIZE,
            payload: res.data,
        });
    })
}


export const setFilterCustomers = (text) => (dispatch) => {
    dispatch({
        type: SEARCH_TEXT,
        payload: text,
    })
}
