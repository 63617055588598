import React, {lazy} from 'react';
import {Navigate} from 'react-router-dom';

import {AuthGuard} from '../auth/AuthGuard/AuthGuard';
import {authRoles} from "../auth/authRoles";
import {WrapperComponentSuspense} from 'components/hoc/WrapperComponentSuspense';
import {MatxLayout} from '../components/Matx/MatxLayout/MatxLayout';
import {
    analyze_path, report_cashback_path, start_app_path, accrual_path, settings_popular_path, settings_limits_path,
    balance_path, statistic_path, barcode_path, limit_path, signin_path
} from "../navigations/navigations";


const Dashboard = WrapperComponentSuspense(lazy(() => import("../pages/dashboard/Dashboard")));
const AppHistory = WrapperComponentSuspense(lazy(() => import('../pages/balance/constituents/AppBalanceHistory')));
const AppStatistic = WrapperComponentSuspense(lazy(() => import('../pages/statistic/constituents/AppStatisticHistory')));
const Analyze = WrapperComponentSuspense(lazy(() => import("../pages/analyze/Analyze")));
const Cashback = WrapperComponentSuspense(lazy(() => import('../pages/cashback/Cashback')));
const CashbackReport = WrapperComponentSuspense(lazy(() => import('../pages/cashback_report/CashbackReport')));
const AppBarcode = WrapperComponentSuspense(lazy(() => import('../pages/barcode/constituents/AppBarcode')));
const Limit = WrapperComponentSuspense(lazy(() => import("../pages/limit/Limit")));

const SettingsPopular = WrapperComponentSuspense(lazy(() => import('../pages/settings/SettingsPopular')));
const SettingsLimits = WrapperComponentSuspense(lazy(() => import('../pages/settings/SettingsLimits')));

const JwtLogin = WrapperComponentSuspense(lazy(() => import("../pages/sessions/JwtLogin/JwtLogin")));
const NotFound = WrapperComponentSuspense(lazy(() => import("../pages/sessions/NotFound")));


export const AllPages = () => {
    return [
        {
            element: (
                <AuthGuard>   {/* skips to the pages on which it is hung according to the access level */}
                    <MatxLayout />  {/* Base Layout */}
                </AuthGuard>
            ),
            children: [
                { path: start_app_path,        element: <Dashboard />,        auth: authRoles.staff },
                { path: balance_path,          element: <AppHistory />,        auth: authRoles.staff },
                { path: statistic_path,        element: <AppStatistic />,      auth: authRoles.staff },
                { path: analyze_path,          element: <Analyze />,          auth: authRoles.staff },
                { path: accrual_path,          element: <Cashback />,         auth: authRoles.staff },
                { path: report_cashback_path,  element: <CashbackReport />,   auth: authRoles.staff },
                { path: barcode_path,          element: <AppBarcode />,        auth: authRoles.staff },
                { path: limit_path,            element: <Limit />,         auth: authRoles.staff },
                { path: settings_popular_path, element: <SettingsPopular />,  auth: authRoles.staff },
                { path: settings_limits_path,  element: <SettingsLimits />,   auth: authRoles.staff },
            ],
        },
        { path: signin_path, element: <JwtLogin />, },
        { path: '/',         element: <Navigate to={start_app_path} />, },
        { path: '*',         element: <NotFound />, },
    ]
}
