import {setOpenWebSocketConnection} from "../../redux/actions/WebSocketAction";
import {isClosedWebSocketConnection, isOpenWebSocketConnection} from "./WebSocketStates";

export class WebSocketService {

    static createWebSocketConnection(dispatchWebSocket, userRole, userId) {
        const $ws = new WebSocket(
            `${process.env.REACT_APP_HOST_WEBSOCKET}ws?sid=${process.env.REACT_APP_ADMIN_SID}&role=${userRole}&id=${userId}`
        );
        dispatchWebSocket(setOpenWebSocketConnection($ws));

        $ws.onopen = () => {
            if (isOpenWebSocketConnection($ws)) { console.log('Create WebSocketConnection successfully'); }
        };  // callback for event opening a connection

        $ws.onclose = () => {
            if (isClosedWebSocketConnection($ws)) { console.log('Close WebSocketConnection successfully'); }
        }; // callback for event closing the connection

        $ws.onerror = (error) => {
            console.log('WebSocket Error ' + error);
        };
    };

    static closeWebSocketConnection(webSocketConnection) {
        webSocketConnection.close();
    }

}
