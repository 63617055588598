import {$api} from "../../api/indexAPI";
import {
    api_customers_prop_gifts_list_url,
    api_customer_prop_gifts_giftery_url,
    api_customers_prop_gifts_ozon_url,
    api_customers_prop_gifts_digift_url
} from "../../api/apiUrls";


export const GET_ALL_GIFTS = 'GET_ALL_GIFTS';
export const BUY_GIFT = 'BUY_GIFT';

export const GET_USER_GIFTERY_ORDERS = 'GET_USER_GIFTERY_ORDERS';
export const SEND_GIFT_GIFTERY = 'SEND_GIFT_GIFTERY';

export const GET_USER_OZON_ORDERS = 'GET_USER_OZON_ORDERS';
export const SEND_GIFT_OZON = 'SEND_GIFT_OZON';
export const REMOVE_GIFT_OZON = 'REMOVE_GIFT_OZON';

export const GET_USER_DIGIFT_ORDERS = 'GET_USER_DIGIFT_ORDERS'


export const getGiftList = (phone) => (dispatch) => {
    $api.get(api_customers_prop_gifts_list_url, {params: {'phone': phone,}}).then((res) => {
        dispatch({
            type: GET_ALL_GIFTS,
            payload: res.data.result,
        });
    });
}

export const getGiftGiftery = (phone) => (dispatch) => {
    $api.get(api_customer_prop_gifts_giftery_url, {params: {'phone': phone,}}).then((res) => {
        dispatch({
            type: GET_USER_GIFTERY_ORDERS,
            payload: res.data.result,
        });
    });
}

export const getGiftOzon = (phone) => (dispatch) => {
    $api.get(api_customers_prop_gifts_ozon_url, {params: {'phone': phone,}}).then((res) => {
        dispatch({
            type: GET_USER_OZON_ORDERS,
            payload: res.data.result,
        });
    });
}

export const getGiftDigift = (phone) => (dispatch) => {
    $api.get(api_customers_prop_gifts_digift_url, {params: {'phone': phone,}}).then((res) => {
        dispatch({
            type: GET_USER_DIGIFT_ORDERS,
            payload: res.data.result,
        });
    });
}
