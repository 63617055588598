import {ADD_LIMIT_USER_PERMIT, GET_LIMIT_USER_PERMIT, REMOVE_LIMIT_USER_PERMIT} from "../actions/LimitActions";

const initialState = []


export const LimitReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_LIMIT_USER_PERMIT: {
            return [...action.payload]
        }
        case REMOVE_LIMIT_USER_PERMIT: {
            return [...state.filter(item => item.email !== action.payload.email)]
        }
        case ADD_LIMIT_USER_PERMIT: {
            return [...state, action.payload]
        }
        default: {
            return state
        }

    }
}
