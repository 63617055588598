import {forEach, merge} from 'lodash'
import {themeOptions} from './themeOptions'
import {themeColors} from './themeColors'
import {createTheme} from '@mui/material'
import {ruRU} from '@mui/material/locale';

function createMatxThemes() {
    let themes = {}

    forEach(themeColors, (value, key) => {
        themes[key] = createTheme(merge({}, themeOptions, value), ruRU)
    })
    return themes
}

export const themes = createMatxThemes()
