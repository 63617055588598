import {
    GET_ALL_GIFTS,
    BUY_GIFT,

    GET_USER_GIFTERY_ORDERS,
    SEND_GIFT_GIFTERY,

    GET_USER_OZON_ORDERS,
    SEND_GIFT_OZON,
    REMOVE_GIFT_OZON,

    GET_USER_DIGIFT_ORDERS
} from "../actions/GiftActions";

const initialState = {
    giftList: [],
    buyGift: {},

    giftOrderGiftary: [],
    sendGiftGiftery: {},

    giftOrderOzon: [],
    sendGiftOzon: {},
    removeGiftOzon: {},

    giftOrderDigift: [],
}

export const GiftReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_ALL_GIFTS: {
            return {...state, giftList: action.payload};
        }
        case BUY_GIFT: {
            return {...state, buyGift: {...action.payload}};
        }

        case GET_USER_GIFTERY_ORDERS: {
            return {...state, giftOrderGiftary: action.payload};
        }
        case SEND_GIFT_GIFTERY: {
            return {...state, sendGiftGiftery: action.payload};
        }

        case GET_USER_OZON_ORDERS: {
            return {...state, giftOrderOzon: action.payload};
        }
        case SEND_GIFT_OZON: {
            return {...state, sendGiftOzon: action.payload};
        }
        case REMOVE_GIFT_OZON: {
            return {...state, removeGiftOzon: action.payload};
        }

        case GET_USER_DIGIFT_ORDERS: {
            return {...state, giftOrderDigift: action.payload};
        }

        default: {
            return state;
        }
    }
}

