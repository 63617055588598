import React, {useState} from "react";
import {MatxLayoutSettings} from "../../../components/Matx/MatxLayout/MatxLayoutSettings";
import {merge} from "lodash";
import {SettingsContext} from "../../SettingsContext";


export const SettingsProvider = ({settings, children}) => {
    const [currentSettings, setCurrentSettings] = useState(settings || MatxLayoutSettings);

    const handleUpdateSettings = (update = {}) => {
        const marged = merge({}, currentSettings, update);
        setCurrentSettings(marged);
    }

    return (
        <SettingsContext.Provider value={{settings: currentSettings, updateSettings: handleUpdateSettings}}>
            {children}
        </SettingsContext.Provider>
    );
}
