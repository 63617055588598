import {combineReducers} from 'redux';

import {NotificationReducer}             from './reducers/NotificationReducer';
import {NavigationReducer}               from './reducers/NavigationReducer';
import {AnalizeReducer}                  from "./reducers/AnalizeReducer";
import {MemberReducer}                   from "./reducers/MemberReducer";
import {LimitReducer}                    from "./reducers/LimitReducer";
import {GiftReducer}                     from "./reducers/GiftsReducer";
import {AddressReducer}                  from "./reducers/AddressReducer";
import {CustomerReducer}                 from "./reducers/CustomerReducer";
import {CashbackMembersReducer}          from "./reducers/CashbackMemberReducer";
import {ParamsDashboardMainTableReducer} from "./reducers/ParamsDashboardMainTableReducer";
import {WebSocketReducer}                from "./reducers/WebSocketReducer";


export const RootReducer = combineReducers({
    notifications: NotificationReducer,
    navigations: NavigationReducer,
    members: AnalizeReducer,
    selected_member: MemberReducer,
    cashback_members: CashbackMembersReducer,
    user_permit_limit: LimitReducer,
    gifts: GiftReducer,
    address: AddressReducer,
    customers: CustomerReducer,
    params_dashboard_main_table: ParamsDashboardMainTableReducer,
    WebSocketConnections: WebSocketReducer,
})
