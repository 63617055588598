export const signin_path = '/signin';
export const start_app_path = '/dashboard';
export const balance_path = '/balance';
export const statistic_path = '/statistic';
export const analyze_path = '/analyze';
export const accrual_path = '/accrual';
export const report_cashback_path = '/report_cashback';
export const barcode_path = '/barcode';
export const limit_path = '/limit';
export const settings_popular_path = '/settings/popular';
export const settings_limits_path = '/settings/limits';


export const navigations = [
    {
        name: 'Участники',
        icon: 'dashboard',
        path: start_app_path,
    },
    {
        name: 'История баланса',
        icon: 'history',
        path: balance_path,
    },
    {
        name: 'Статистика заказы',
        icon: 'list',
        path: statistic_path,
    },
    {
        name: 'Отчеты по участникам',
        icon: 'compare_arrows',
        path: analyze_path,
    },
    {
        name: 'Начисления (cashback)',
        icon: 'style',
        path: accrual_path,
    },
    {
        name: 'Отчеты (cashback)',
        icon: 'launch',
        path: report_cashback_path,
    },
    {
        name: 'Штрих-кода',
        icon: 'qr_code_scanner',
        path: barcode_path,
    },
    {
        name: 'Лимиты',
        icon: 'groups',
        path: limit_path,
    },
    {
        label: 'ССЫЛКИ',
        type: 'label',
    },
    {
        name: 'Giftery',
        icon: 'card_giftcard',
        type: 'extLink',
        path: 'https://b2b.giftery.ru/cabinet',
    },
    {
        name: 'СМС центр',
        icon: 'sms',
        type: 'extLink',
        path: 'https://smsc.ru/',
    },
    {
        name: 'Админка django',
        icon: 'cancel',
        type: 'extLink',
        path: `${process.env.REACT_APP_ADMIN_HOST}`,
    },
    // {
    //     name: 'Веб кабинет',
    //     icon: 'sms',
    //     path: 'webview/',
    // },
    {
        label: 'НАСТРОЙКИ',
        type: 'label',
    },
    {
        name: 'Группа популярные',
        icon: 'list',
        path: settings_popular_path,
    },
    {
        name: 'Лимиты',
        icon: 'style',
        path: settings_limits_path,
    },
]

export const navigationsSTAFF = [
    {
        name: 'Лимиты',
        icon: 'groups',
        path: limit_path,
    },
]
