export const straightenArray = (array) => {
    let result = [];
    array.forEach(function (item) {
        result.push(item);
        if (Array.isArray(item.children)) {
            result = result.concat(straightenArray(item.children));
        }
    });
    return result;
};
