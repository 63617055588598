export const CREATE_WEBSOCKET_CONNECTION = 'CREATE_WEBSOCKET_CONNECTION';
export const CLOSE_WEBSOCKET_CONNECTION = 'CLOSE_WEBSOCKET_CONNECTION';


export const setOpenWebSocketConnection = (WebSocketConnection) => (dispatch) => {
    dispatch({type: CREATE_WEBSOCKET_CONNECTION, payload: WebSocketConnection});
}

export const setCloseWebSocketConnection = () => (dispatch) => {
    dispatch({type: CLOSE_WEBSOCKET_CONNECTION, payload: null});
}