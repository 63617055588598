import {GET_ALL_CUSTOMERS, PAGES_SIZE, SEARCH_TEXT} from "../actions/CustomersActions";

const initialState = {
    pages: [],
    list: [],
    filter: 'null',
}

export const CustomerReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_ALL_CUSTOMERS: {
            return {...state, list: action.payload};
        }
        case PAGES_SIZE: {
            return {...state, pages: action.payload};
        }
        case SEARCH_TEXT: {
            return {...state, filter: action.payload};
        }
        default: {
            return state;
        }
    }
}