export const SET_COUNT_ROWS = 'SET_COUNT_ROWS';
export const SET_MEMBER_STATUS = 'SET_MEMBER_STATUS';


export const setCountRowsMainTable = (countRows) => (dispatch) => {
    dispatch({
        type: SET_COUNT_ROWS,
        payload: countRows,
    })
}

export const setMemberStatusMainTable = (memberStatus) => (dispatch) => {
    dispatch({
        type: SET_MEMBER_STATUS,
        payload: memberStatus,
    })
}