import React from 'react';
import {useSettings} from 'hooks/useSettings';
import {CssBaseline, ThemeProvider} from '@mui/material';


export const MatxTheme = ({children}) => {
    const {settings} = useSettings();
    let activeTheme = {...settings.themes[settings.activeTheme]};
    return (
        <ThemeProvider theme={activeTheme}>
            <CssBaseline/>
            {children}
        </ThemeProvider>
    );
}
