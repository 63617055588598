import React, {useContext} from 'react';
import {Navigate, useLocation} from 'react-router-dom';

import {straightenArray} from 'utils/arrayProcessing';
import {AllPages} from '../../routes/routes';
import {AuthContext} from "../../contexts/AuthContext";
import {userHasPermission} from "./auxAuthGuard";
import {NoAccessRightsGuest} from "../NoRightsComponents/NoAccessRightsGuest";
import {signin_path} from "../../navigations/navigations";


/** AuthGuard - component,
 * skips to the pages on which it is hung according to the access level*/
export const AuthGuard = ({children}) => {
    const {isAuthenticated, user} = useContext(AuthContext);
    const {pathname} = useLocation();
    const routes = straightenArray(AllPages());
    const hasPermission = userHasPermission(user, pathname, routes);
    const isValidUser = isAuthenticated && hasPermission;

    if (isValidUser) {
        return <> {children} </>
    } else {
        if (user && user.role === "GUEST") {
            return <NoAccessRightsGuest />
        }
        return ( <Navigate to={signin_path} replace={true} state={{ from: pathname }} /> );
    }

}
