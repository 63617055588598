import React from 'react';
import Typography from "@mui/material/Typography";
import {JustifyBox} from "../../components/containers/JustifyBox/JustifyBox";


export const NoRights = ({message}) => {
    return (
        <JustifyBox>
            <Typography variant={"h6"}>{message}</Typography>
        </JustifyBox>
    );
}