import {UserService} from "../../../services/UserService";


export const reducer = (state, action) => {
    switch(action.type) {
        case 'GET_USER_DATA':
            return {
                isAuthenticated: true,
                isInitialised: true,
                user: action.payload.user,
            };
        case 'LOGOUT':
            return {
                isAuthenticated: false,
                isInitialised: true,
                user: null,
            };
        case 'NO_TOKEN':
            return {
                isAuthenticated: false,
                isInitialised: true,
                user: null,
            };
        case 'ERROR':
            return {
                isAuthenticated: false,
                isInitialised: false,
                user: null,
            };
        default:
            throw new Error('Unknown action');
    }
};


export const enteredAppUser = async (dispatch, text) => {
    let user = await UserService.getUser();
    dispatch({ type: 'GET_USER_DATA', payload: {user} });
    console.log(`${text} successfully`);
    return user;
}
