import {CREATE_WEBSOCKET_CONNECTION, CLOSE_WEBSOCKET_CONNECTION} from "../actions/WebSocketAction";

const initialState = {
    WebSocketConnection: null,
}


export const WebSocketReducer = function (state = initialState, action) {
    switch (action.type) {
        case CREATE_WEBSOCKET_CONNECTION: {
            return {...state, WebSocketConnection: action.payload};
        }
        case CLOSE_WEBSOCKET_CONNECTION: {
            return {...state, WebSocketConnection: action.payload};
        }
        default: {
            return state;
        }
    }
}