const AUTH_JWT_URL = '/frontend/api/v2/auth-jwt';

export const api_login_url         = `${AUTH_JWT_URL}/login/`;  // create 2 tokens and set their cookies  // POST +
export const api_get_user_data_url = `${AUTH_JWT_URL}/get_user_data/`;                                    // GET  +
export const api_verify_url        = `${AUTH_JWT_URL}/verify/`;                                           // GET  +
export const api_refresh_url       = `${AUTH_JWT_URL}/refresh/`;                                          // POST +
export const api_logout_url        = `${AUTH_JWT_URL}/logout/`;                                           // POST +

// ====================================================================================================================

const BASE_API_URL = '/frontend';

// ====================================================================================================================
export const api_statistic_balance_url             = `${BASE_API_URL}/statistic/balance`;
export const api_statistic_count_url               = `${BASE_API_URL}/statistic/count`;
export const api_statistic_balance_story_url       = `${BASE_API_URL}/statistic/balance_story`;
export const api_statistic_await_order_url         = `${BASE_API_URL}/statistic/await_order`;
export const api_statistic_balance_charges_url     = `${BASE_API_URL}/statistic/balance_charges`;
// ====================================================================================================================
// =========================================== get info ===============================================================
// ====================================================================================================================
export const api_customers_analytics_url           = `${BASE_API_URL}/customers_analytics`;
export const api_territory_url                     = `${BASE_API_URL}/territory`;
export const api_barcode_url                       = `${BASE_API_URL}/barcode`;
export const api_get_branches_url                  = `${BASE_API_URL}/get_branches`;
export const api_get_regions_url                   = `${BASE_API_URL}/get_regions`;
export const api_nomenclature_url                  = `${BASE_API_URL}/nomenclature`;
// ====================================================================================================================
export const api_customers_list_url                = `${BASE_API_URL}/customers_list`;
export const api_customers_list_addresses_url      = `${BASE_API_URL}/customers_list_addresses`;          // GET
export const api_customers_list_addresses_add_url  = `${BASE_API_URL}/customers_list_addresses_add`;
export const api_customers_list_addresses_del_url  = `${BASE_API_URL}/customers_list_addresses_del`;
export const api_customers_list_addresses_main_url = `${BASE_API_URL}/customers_list_addresses_main`;
// ====================================================================================================================
export const api_customers_prop_main_url           = `${BASE_API_URL}/customers_prop_main`;
export const api_customers_prop_addr_url           = `${BASE_API_URL}/customers_prop_addr`;
export const api_customers_prop_scan_url           = `${BASE_API_URL}/customers_prop_scan`;
export const api_customers_prop_unscan_url         = `${BASE_API_URL}/customers_prop_unscan`;
export const api_customers_prop_transfer_url       = `${BASE_API_URL}/customers_prop_transfer`;
export const api_customers_prop_limit_url          = `${BASE_API_URL}/customers_prop_limit`;
export const api_customers_prop_delivery_url       = `${BASE_API_URL}/customers_prop_delivery`;
export const api_customers_prop_correction_url     = `${BASE_API_URL}/customers_prop_correction`;
// ====================================================================================================================
// ================================= common marketplaces ==============================================================
// ====================================================================================================================
export const api_get_all_gifts_url                 = `${BASE_API_URL}/get_all_gifts`;
export const api_customers_prop_gifts_list_url     = `${BASE_API_URL}/customers_prop_gifts_list`;
export const api_buy_gift_url                      = `${BASE_API_URL}/buy_gift`;
// ====================================================================================================================
// ================================= giftery marketplaces =============================================================
// ====================================================================================================================
export const api_customer_prop_gifts_giftery_url   = `${BASE_API_URL}/customer_prop_gifts_giftery`;
export const api_send_giftery_gift_url             = `${BASE_API_URL}/send_giftery_gift`;  // send certificate by email
export const api_set_popular_gifts_url             = `${BASE_API_URL}/set_popular_gifts`;
// ====================================================================================================================
// =================================== ozon marketplaces ==============================================================
// ====================================================================================================================
export const api_customers_prop_gifts_ozon_url     = `${BASE_API_URL}/customers_prop_gifts_ozon`;
export const api_send_ozon_gift_url                = `${BASE_API_URL}/send_ozon_gift`;     // send certificate by email
export const api_customers_remove_ozon_url         = `${BASE_API_URL}/customers_remove_ozon`;
// ====================================================================================================================
// =================================== digift marketplaces ============================================================
// ====================================================================================================================
export const api_customers_prop_gifts_digift_url   = `${BASE_API_URL}/customers_prop_gifts_digift`;
export const api_send_digift_gift_url              = `${BASE_API_URL}/send_digift_gift`;  // send certificate by email
// ====================================================================================================================
// =================================== limit permissions ==============================================================
// ====================================================================================================================
export const api_get_user_limit_url                = `${BASE_API_URL}/get_user_limit`;
export const api_limit_goods_url                   = `${BASE_API_URL}/limit_goods`;
export const api_get_limit_customer_branches_url   = `${BASE_API_URL}/get_limit_customer_branches`;
export const api_get_limit_data_url                = `${BASE_API_URL}/get_limit_data`;
export const api_remove_user_limit_url             = `${BASE_API_URL}/remove_user_limit`;
export const api_add_user_limit_url                = `${BASE_API_URL}/add_user_limit`;
export const api_get_limit_counterparty_url        = `${BASE_API_URL}/get_limit_counterparty`;
export const api_send_limit_by_email_url           = `${BASE_API_URL}/send_limit_by_email`;
// ====================================================================================================================
// ============================================= actions ==============================================================
// ====================================================================================================================
export const api_recovery_password_url               = `${BASE_API_URL}/recovery_password`;
export const api_customer_update_or_create_url       = `${BASE_API_URL}/customer_update_or_create`;
export const api_switch_allow_gifts_url              = `${BASE_API_URL}/switch_allow_gifts`;
export const api_switch_allow_transactions_url       = `${BASE_API_URL}/switch_allow_transactions`;
export const api_switch_enable_url                   = `${BASE_API_URL}/switch_enable`;
export const api_update_rep_date_url                 = `${BASE_API_URL}/update_rep_date`;
export const api_remove_customer_url                 = `${BASE_API_URL}/remove_customer`;
export const api_change_balance_url                  = `${BASE_API_URL}/change_balance`;
export const api_customers_get_not_scanned_items_url = `${BASE_API_URL}/customers_get_not_scanned_items`;
export const api_update_push_status_url              = `${BASE_API_URL}/update_push_status`;
export const api_send_link_apk_file_url              = `${BASE_API_URL}/send_link_apk_file`;
export const api_get_link_apk_file_url               = `${BASE_API_URL}/get_link_apk_file`;
// ====================================================================================================================
// ========================================accruals cashback===========================================================
// ====================================================================================================================
export const api_cashback_accruals_file_url            = `${BASE_API_URL}/cashback_accruals_file`;
export const api_customer_is_cashback_url              = `${BASE_API_URL}/customer_is_cashback`;
export const api_history_accruals_url                  = `${BASE_API_URL}/history_accruals`;
export const api_history_accruals_detail_url           = `${BASE_API_URL}/history_accruals_detail`;
export const api_history_accruals_detail_by_filter_url = `${BASE_API_URL}/history_accruals_detail_by_filter`;
