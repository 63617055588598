import {
    GET_SELECTED_CASHBACK_MEMBERS,
    SET_IS_LOADING_SELECTED_CASHBACK_MEMBERS_TRUE,
    SET_IS_LOADING_SELECTED_CASHBACK_MEMBERS_FALSE
} from "../actions/CashbackMemberActions";


const initialState = {
    cashback_members: [],
    isLoadingCashbackMembers: false,
}

export const CashbackMembersReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_SELECTED_CASHBACK_MEMBERS: {
            return {...state, cashback_members: action.payload};
        }
        case SET_IS_LOADING_SELECTED_CASHBACK_MEMBERS_TRUE: {
            return {...state, cashback_members: [], isLoadingCashbackMembers: action.payload};
        }
        case SET_IS_LOADING_SELECTED_CASHBACK_MEMBERS_FALSE: {
            return {...state, cashback_members: [], isLoadingCashbackMembers: action.payload};
        }
        default: {
            return state;
        }
    }
}
