import {$api} from "../../api/indexAPI";
import {api_get_user_limit_url, api_remove_user_limit_url} from "../../api/apiUrls";

export const GET_LIMIT_USER_PERMIT = 'GET_LIMIT_USER_PERMIT'
export const REMOVE_LIMIT_USER_PERMIT = 'REMOVE_LIMIT_USER_PERMIT'
export const ADD_LIMIT_USER_PERMIT = 'ADD_LIMIT_USER_PERMIT'

export const getUserPermit = () => (dispatch) => {
    $api.get(api_get_user_limit_url).then((res) => {
        dispatch({
            type: GET_LIMIT_USER_PERMIT,
            payload: res.data.result,
        })
    })
}

export const dellUserPermit = (email) => (dispatch) => {
    $api.get(api_remove_user_limit_url, {
        params:
            {
                'email': email,
            }
    }).then((res) => {
        dispatch({
            type: REMOVE_LIMIT_USER_PERMIT,
            payload: {...res.data, email: email},
        })
    })
}

export const addUserPermit = (email) => (dispatch) => {
    $api.get('/frontend/add_user_limit'
        //     ,{
        //     params:
        //         {
        //             'branches': state.branches.join(", "),
        //             "email": state.email,
        //             "username": state.username,
        //             "firstname": state.firstname,
        //             "lastname": state.lastname
        //         }
        // }
    ).then((res) => {
        dispatch({
            type: REMOVE_LIMIT_USER_PERMIT,
            payload: {...res.data, email: email},
        })
    })
}