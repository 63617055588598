import {GET_ADDRESSES, GET_ADDRESSES_USER} from "../actions/AddressActions";

const initialState = {
    addressList: [],
    addressUserList: []
}

export const AddressReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_ADDRESSES: {
            return {...state, addressList: action.payload};
        }
        case GET_ADDRESSES_USER: {
            return {...state, addressUserList: action.payload};
        }
        default: {
            return state;
        }
    }
}

