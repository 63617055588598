import React from 'react';
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import {CssBaseline} from '@mui/material';

import { App } from './App';

// This file is the entry point of the React application
// It is responsible for rendering components in the DOM

render(
    <React.StrictMode>
        <BrowserRouter>
            <CssBaseline />
            <App />
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);

/*
    <App /> - usually only this say me(SK) Butusov A.
    <React.StrictMode> - a tool for detecting potential problems in the application.
    <BrowserRouter> - SPA technology, project routing


    <StyledEngineProvider injectFirst> - connecting styles(say me(SK) BA).
                                         de-facto - allows you to change styles in mui components
                                         I(SK) delete this, because it didn't apply

    CssBaseline - resetting settings that are better adapted to the Material user interface. I(SK) delete this,
                  because I didn't find a difference with him and without him

    import 'perfect-scrollbar/css/perfect-scrollbar.css'; I(SK) delete this
    https://www.npmjs.com/package/react-perfect-scrollbar
*/

// to remember, suddenly come in handy:
// for IE-11 support un-comment cssVars() and it's import in this file
// and in MatxTheme file

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.js is needed for background tasks or to make a website an independent application

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals