export const authRoles = {
    sa:    ['SA'],                            // Only Super Admin has access
    admin: ['SA', 'ADMIN'],                   // Only SA & Admin has access
    staff: ['SA', 'ADMIN', 'STAFF'],          // Only SA & Admin & Staff has access
    guest: ['SA', 'ADMIN', 'STAFF', 'GUEST'], // Everyone has access
}

export const webSocketAccessRole           = authRoles.staff;

// ========================TopBar============================
// ===================buttons TopBar=========================
export const showSidebarToggleAccessRole   = authRoles.staff;
export const showTopBarAPKAccessRole       = authRoles.admin;
export const showTopBarStatisticAccessRole = authRoles.admin;
export const showChatAccessRole            = authRoles.admin;

// =======================Pages==============================
export const showDashboardAccessRole       = authRoles.admin;
export const showBalanceAccessRole         = authRoles.admin;
export const showStatisticAccessRole       = authRoles.admin;
export const showAnalyzeAccessRole         = authRoles.admin;
export const showAccrualAccessRole         = authRoles.admin;
export const showReportCashbackAccessRole  = authRoles.admin;
export const showBarcodeAccessRole         = authRoles.admin;
export const showLimitAccessRole           = authRoles.staff;
export const showSettingsPopularAccessRole = authRoles.admin;
export const showSettingsLimitsAccessRole  = authRoles.admin;